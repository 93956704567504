@import 'components/global.scss';

.logo {
  display: block;
  width: $scale9;
  height: $scale3;
  margin: 0 auto $scale2;
  outline: none;
}

.text_logo {
  color: #ffffff !important;
  font-family: 'Space Grotesk', sans-serif;
  text-decoration: none !important;
  font-size: 24pt;
}
